import React from "react";
import { PageProps } from "gatsby";
import DefaultLayout from "../../layouts/DefaultLayout";
import Image from "../../components/Image";

const LaminationColumn: React.FC = ({ children }) => (
  <div className="col-6 col-sm-4 col-md-3 pb-3">{children}</div>
);

interface LaminationCardProps {
  title: string;
  width: "wide" | "small";
}

const LaminationCard: React.FC<LaminationCardProps> = ({ title, width }) => {
  const name = `lam_${width}_` + title.replace(/ /g, "_").toLowerCase();

  return (
    <div className="card h-100">
      <div className="card-image">
        <Image name={name} />
      </div>
      <div className="card-body">
        <p className="card-text text-size-small">{title}</p>
      </div>
    </div>
  );
};

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="Benchtops">
    <div className="container my-5">
      <p className="text-size-medium">
        Our timber benchtops can be made to your specific requirements. We have
        a range of different timbers in 20mm-90mm (depending on species)
        laminated timber strips and a range of edge profiles to finish your
        benchtop.
      </p>
      <p className="text-size-medium">
        Timber can be used effectively as tables or bench tops as hardwoods are
        very durable and once polished give a beautiful finish perfect for both
        modern or traditional kitchens.
      </p>
    </div>
    <div className="container mt-5 mb-3">
      <h3 className="text-center">Wide Laminations</h3>
    </div>
    <div className="container my-3">
      <p>
        Wide laminations are perfect to enhance the natural grain of the timber
        as it displays solid pieces of timber is larger sections. Wider
        laminations are better for uniformity of colour especially with longer
        tops as it draws the eye down the length.
      </p>
    </div>
    <div className="container my-3">
      <div className="row justify-content-center">
        <LaminationColumn>
          <LaminationCard title="Spotted Gum" width="wide" />
        </LaminationColumn>
        <LaminationColumn>
          <LaminationCard title="Jarrah" width="wide" />
        </LaminationColumn>
        <LaminationColumn>
          <LaminationCard title="Tasmanian Oak" width="wide" />
        </LaminationColumn>
        <LaminationColumn>
          <LaminationCard title="Baltic Pine" width="wide" />
        </LaminationColumn>
      </div>
    </div>
    <div className="container mt-5 mb-3">
      <h3 className="text-center">Small Laminations</h3>
    </div>
    <div className="container my-3">
      <p>
        Benchtops using 20mm laminations, as the timber strips are smaller there
        is more colour variation across the bench top. Work really well for
        smaller tops but for longer tops it is recommended that the laminations
        be wider so that the colouring matches along the length.
      </p>
    </div>
    <div className="container my-3">
      <div className="row justify-content-center">
        <LaminationColumn>
          <LaminationCard title="Jarrah" width="small" />
        </LaminationColumn>
        <LaminationColumn>
          <LaminationCard title="Rosewood" width="small" />
        </LaminationColumn>
      </div>
    </div>
    <div className="container my-5">
      <h3 className="text-center">Benchtop Styles</h3>
    </div>
    <div className="container my-3">
      <div className="row justify-content-center">
        <div className="col-10 col-sm-8 col-md-6 col-lg-3">
          <Image name="bt_waterfall" />
        </div>
        <div className="col-md-6 col-lg-9">
          <div className="card h-100">
            <div className="card-body">
              <h4 className="card-title">Waterfall Benchtop</h4>
              <p>
                Ideal for statement island counters the 'Waterfall" Bench Top
                has slab ends which join the top to the floor. The top is made
                as one piece of timber and then cut down so that the grain
                matches along the sides giving it a waterfall appearance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container my-3">
      <div className="row justify-content-center">
        <div className="col-10 col-sm-8 col-md-6 col-lg-3">
          <Image name="bt_drop_front" />
        </div>
        <div className="col-md-6 col-lg-9">
          <div className="card h-100">
            <div className="card-body">
              <h4 className="card-title">Drop Front Benchtop</h4>
              <p>
                To add extra thickness to your bench top you can create a solid
                timber look with a 'drop front'. Available in all sizes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
